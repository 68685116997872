import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
} from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';
import { Layout } from './components/modules/Layout/Layout';
import { PageProxy } from './components/modules/PageProxy/PageProxy';

export const DEFAULT_LANGUAGE = 'nl';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Layout />}>
            <Route path=':locale/' element={<PageProxy />} />
            <Route
                path='/'
                element={<Navigate to={`${DEFAULT_LANGUAGE}/`} replace={true} />}
            />

            <Route path={':locale/*'} element={<PageProxy />} />
        </Route>
    )
);
