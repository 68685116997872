import cx from 'classnames';
import { useEffect } from 'react';
import { IMenuItem } from '../../../../../utils/cmsTypes';
import { Arrow } from '../../../../common/Arrow/Arrow';
import styles from './Menu.module.scss';

interface IMenuProps {
    menuOpen: boolean;
    setMenuOpen: (menuOpen: boolean) => void;
    menuItems: IMenuItem[];
    isLoading: boolean;
}

export const Menu = (props: IMenuProps) => {
    const { menuOpen, setMenuOpen, menuItems, isLoading } = props;

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [menuOpen]);

    return (
        <div className={cx(styles.menu, menuOpen && styles['menu--open'])}>
            <div className={styles.menu__content}>
                <div
                    className={styles.menu__close}
                    onClick={() => setMenuOpen(false)}
                ></div>
                <div className={styles.menu__items}>
                    {!isLoading &&
                        menuItems &&
                        menuItems.map((menuItem) => (
                            <div className={styles.menu__item} key={menuItem.id}>
                                <a href={`${menuItem.url}`}>{menuItem.title}</a>
                                <Arrow
                                    direction={'right'}
                                    double
                                    color={menuItem.arrowColor}
                                    className={styles.menu__arrow}
                                    width={20}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
