import cx from 'classnames';
import { ReactNode } from 'react';
import { TPageColors } from 'utils/cmsTypes';
import styles from './Span.module.scss';

interface ISpanProps {
    children: ReactNode;
    bold?: boolean;
    size: 'sm' | 'md' | 'lg' | 'xl';
    color?: TPageColors;
}
export const Span = (props: ISpanProps) => {
    const { children, bold, size, color } = props;
    return (
        <span
            className={cx(
                styles.span,
                bold && styles['span--bold'],
                size === 'sm' && styles['span--sm'],
                size === 'md' && styles['span--md'],
                size === 'lg' && styles['span--lg'],
                size === 'xl' && styles['span--xl'],
                color === 'black' && styles['span--black'],
                color === 'blue' && styles['span--blue'],
                color === 'gray' && styles['span--gray'],
                color === 'green' && styles['span--green'],
                color === 'orange' && styles['span--orange'],
                color === 'red' && styles['span--red']
            )}
        >
            {children}
        </span>
    );
};
