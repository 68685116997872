import { router } from 'App';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import Spinner from './components/common/Spinner/Spinner';
import reportWebVitals from './reportWebVitals';

axios.defaults.baseURL =
    process.env.REACT_APP_API_BASE_URL || `http://localhost:8000/api/v2/`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Spinner />
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
