import React from 'react';
import styles from './Spinner.module.scss';

export interface SpinnerHandle {
    show: () => void;
    hide: () => void;
}

export const spinner: SpinnerHandle = {
    show: () => {
        //
    },
    hide: () => {
        //
    },
};

const Spinner: React.FC = () => {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        spinner.show = () => setVisible(true);
        spinner.hide = () => setVisible(false);
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <div className={styles.spinnerContainer}>
            <div className={styles.spinner}>
                <div className={styles.spinner__icon}>
                    <img src='/images/spinner.svg' alt='logo' />
                </div>
            </div>
        </div>
    );
};

export default Spinner;
