import { Span } from 'components/common/Typography/Span/Span';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './LanguagePicker.module.scss';

interface ILanguagePickerProps {
    languageOpen: boolean;
    setLanguageOpen: (languageOpen: boolean) => void;
}
export const LanguagePicker = (props: ILanguagePickerProps) => {
    const { languageOpen, setLanguageOpen } = props;
    const navigate = useNavigate();
    const currentLocale = useParams().locale;

    const handleLanguageClick = (newLocale: string) => {
        setLanguageOpen(!languageOpen);
        navigate(`/${newLocale}`);
        navigate(0);
    };

    return (
        <div className={`${styles.languagePicker} ${languageOpen ? styles.open : ''}`}>
            <div className={styles.languagePicker__title}>
                <RxCross1
                    size={35}
                    color={'black'}
                    onClick={() => setLanguageOpen(false)}
                    cursor={'pointer'}
                />
                <Span size='md' bold color='black'>
                    {currentLocale === 'en' && <>Choose a Language</>}
                    {currentLocale === 'nl' && <>Kies een taal</>}
                </Span>
            </div>
            <div
                className={styles.languageOption}
                onClick={() => handleLanguageClick('en')}
            >
                <img src='/images/flag_gb_4x3.svg' alt='English' />
                <div className={styles.languageOption__text}>
                    <Span size={'md'}>
                        {currentLocale === 'en' && <>English</>}
                        {currentLocale === 'nl' && <>Engels</>}
                    </Span>
                </div>
            </div>
            <div
                className={styles.languageOption}
                onClick={() => handleLanguageClick('nl')}
            >
                <img src='/images/flag_nl_4x3.svg' alt='Dutch' />
                <div className={styles.languageOption__text}>
                    <Span size={'md'}>
                        {currentLocale === 'en' && <>Dutch</>}
                        {currentLocale === 'nl' && <>Nederlands</>}
                    </Span>
                </div>
            </div>
        </div>
    );
};
