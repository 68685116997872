import { DEFAULT_LANGUAGE } from 'App';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { getMenuPages } from '../../utils/api';
import { IMenuItem } from '../../utils/cmsTypes';

export const useGetMenuItems = () => {
    const locale = useParams().locale;
    const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffectOnce(() => {
        const fetchMenuItems = async () => {
            setIsLoading(true);
            const menuItems = await getMenuPages(locale || DEFAULT_LANGUAGE);
            if (!menuItems) {
                setIsLoading(false);
                return console.log('No response');
            }
            setIsLoading(false);
            setMenuItems(menuItems);
        };

        fetchMenuItems().then((r) => r);
    });

    return { menuItems, isLoading };
};
