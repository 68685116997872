import { useState } from 'react';
import { RxCross1, RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { removeLocaleFromPath } from '../../../../utils/api';
import { useGetMenuItems } from '../../../hooks/useGetMenuItems';
import { LanguagePicker } from './LanguagePicker/LanguagePicker';
import { Menu } from './Menu/Menu';
import styles from './Navbar.module.scss';

export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const currentLocale = useParams().locale;

    const path = removeLocaleFromPath(location.pathname || '');

    const { menuItems, isLoading } = useGetMenuItems();

    const handleHomeButtonClick = () => {
        if (currentLocale?.length !== 2) {
            navigate('/');
            return;
        }
        navigate(`/${currentLocale}`);
        navigate(0);
    };

    const lightIconPaths = ['', '/', 'contact/', 'contact'];
    const color = menuOpen ? 'black' : lightIconPaths.includes(path) ? 'white' : 'black';

    const logoSrc =
        color === 'black' ? '/images/logo_black_text.png' : '/images/logo_white_text.png';
    return (
        <>
            <div className={styles.navbar}>
                <div className={styles.navbar__logo} onClick={handleHomeButtonClick}>
                    <img src={logoSrc} alt={'Kieskompas'} />
                </div>
                <div className={styles.navbar__right}>
                    <div
                        className={styles.navbar__language}
                        onClick={() => setLanguageOpen(!languageOpen)}
                    >
                        <img
                            src={'/images/languageGlobe-icon.svg'}
                            width={'45px'}
                            height={'45px'}
                        />
                    </div>
                    <div
                        className={styles.navbar__hamburger}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        {menuOpen ? (
                            <RxCross1 size={45} color={color} />
                        ) : (
                            <RxHamburgerMenu size={45} color={color} />
                        )}
                    </div>
                </div>
            </div>
            <Menu
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                isLoading={isLoading}
                menuItems={menuItems}
            />
            <LanguagePicker
                languageOpen={languageOpen}
                setLanguageOpen={setLanguageOpen}
            />
        </>
    );
};
