import cx from 'classnames';
import { TPageColors } from '../../../utils/cmsTypes';
import styles from './Arrow.module.scss';

interface IArrowProps {
    direction: 'left' | 'right' | 'up' | 'down';
    onClick?: () => void;
    className?: string;
    double?: boolean;
    color?: TPageColors;
    width: number;
}

export const Arrow = (props: IArrowProps) => {
    const { width, color, double, direction, onClick, className } = props;
    return (
        <div
            className={cx(
                styles.arrow,
                styles[`arrow--${direction}`],
                className,
                double && styles[`arrow--double`],
                width === 20 && styles[`arrow--small`] // Add small arrow class for width 20
            )}
        >
            <img
                src={`/images/arrows/arrow--${color}.svg`}
                alt={direction}
                onClick={onClick}
                width={width}
            />
            {double && (
                <img
                    src={`/images/arrows/arrow--${color}.svg`}
                    alt={direction}
                    className={cx(styles.arrow__img__double)}
                    onClick={onClick}
                    width={width}
                />
            )}
        </div>
    );
};
