import axios from 'axios';
import { IMenuItem, TMultiplePages, TPage, TPageColors } from './cmsTypes';

export const getPage = async (path: string, locale: string) => {
    if (locale.length !== 2) {
        return;
    }
    const findPageUrl = `pages/find/?html_path=${path}&locale=${locale}`;
    // Herre we call the API endpoint to get the data for this path
    // and return it.
    const response = await axios.get(findPageUrl).catch(errorHandler);
    if (!response || !response.data.meta) {
        const getRedirectUrl = `redirects/?html_path=${path}&locale=${locale}/`;
        const redirect = await axios
            .get<
                {
                    redirect_page_url: string;
                }[]
            >(getRedirectUrl)
            .catch(errorHandler);
        if (redirect && redirect.data) {
            const redirectData = redirect.data[0];
            if (!redirectData) return;
            const redirectUrl = redirectData.redirect_page_url;
            let url = '';
            if (redirectUrl.includes('localhost:8000')) {
                url = redirectUrl.replace('localhost:8000', 'localhost:3000');
            } else {
                url = redirectUrl.replace('http://', 'https://');
            }
            const data = {
                redirectUrl: url,
            };
            return data;
        }
        return;
    }
    const { meta, title, id, body, bigArrowOption, arrowColor, theme } =
        response.data as TPage;
    const formattedData: TPage = {
        meta,
        title,
        id,
        body,
        bigArrowOption,
        arrowColor,
        theme,
    };
    return formattedData;
};
export const getPageSlug = async (id: number) => {
    const findPageUrl = `pages/${id}/`;
    // Herre we call the API endpoint to get the data for this path
    // and return it.
    const response = await axios.get(findPageUrl).catch(errorHandler);
    if (!response) {
        return;
    }
    const { meta } = response.data as TPage;
    const { type, html_url } = meta;
    let url = '';
    if (html_url.includes('localhost:8000')) {
        url = html_url.replace('localhost:8000', 'localhost:3000');
    } else {
        url = html_url.replace('http://', 'https://');
    }
    return type === 'pages.HomePage' ? '' : url;
};

export const getDocument = async (id: number) => {
    const response = await axios.get(`documents/${id}/`).catch(errorHandler);
    if (!response) {
        return;
    }
    const { meta } = response.data;
    const { download_url } = meta as { download_url: string };
    return download_url;
};

export const getMenuPages = async (locale: string) => {
    const response = await axios
        .get(`pages/?show_in_menus=true&locale=${locale}`)
        .catch(errorHandler);
    if (!response) {
        return;
    }
    const { items } = response.data as TMultiplePages;
    const colors: TPageColors[] = ['blue', 'orange', 'green', 'gray', 'black'];

    return items.map((item, index) => {
        const { meta, title, id } = item;
        const tempArrowColor = colors[index % colors.length];
        let url = '';
        if (meta.html_url.includes('localhost:8000')) {
            url = meta.html_url.replace('localhost:8000', 'localhost:3000');
        } else {
            url = meta.html_url;
        }
        return { url, title, arrowColor: tempArrowColor, id } satisfies IMenuItem;
    });
};

export const getImageUrl = async (id: number) => {
    const response = await axios.get(`images/${id}/`).catch(errorHandler);
    if (!response) {
        return null;
    }
    let apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/v2/';
    if (apiUrl.endsWith('/api/v2')) {
        apiUrl += '/';
    }
    const apiDomain = apiUrl?.replace('/api/v2/', '');
    return `${apiDomain}/${response.data.meta.download_url}`;
};

const errorHandler = (error: any) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
};

export const removeLocaleFromPath = (path: string) => {
    const regex = /^\/(en|nl|fr|de|es)(\/|$)/;
    return path.replace(regex, '');
};
