import { DEFAULT_LANGUAGE } from 'App';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { getPage } from '../../../utils/api';
import Spinner, { spinner } from '../../common/Spinner/Spinner';
import PageTypes from './PageTypes';

export const PageProxy = () => {
    const location = useLocation();
    const locale = useParams().locale;

    const [pageComponent, setPageComponent] = useState<JSX.Element | null>(null);

    useEffect(() => {
        (async () => {
            try {
                spinner.show();
                if (!location.pathname) return;
                const pathWithoutLocale = location.pathname.replace(`/${locale}`, '');
                const data = await getPage(pathWithoutLocale, locale || DEFAULT_LANGUAGE);
                // we check if data has a property called 'meta'
                if (data && 'redirectUrl' in data) {
                    window.location.href = data.redirectUrl;
                    return;
                }
                const PageComponent =
                    data && data.meta && data.meta.type
                        ? PageTypes[data.meta.type]
                        : React.lazy(() => import('../404/404'));
                document.title = data ? data.meta.seo_title : '';
                const el = document.querySelector("meta[name='description']"); // Not my favourite way to do this, alternative would be using React Helmet or something.
                data && el?.setAttribute('content', data.meta.search_description);
                const PageElement = <PageComponent pageData={data} />;
                setPageComponent(PageElement);
            } catch (e) {
                console.log(e);
            }
            spinner.hide();
        })();
    }, [location.pathname, locale]);

    return <React.Suspense fallback={<Spinner />}>{pageComponent}</React.Suspense>;
};
