import { useOutlet } from 'react-router-dom';
import { Footer } from './Footer/Footer';
import styles from './Layout.module.scss';
import { Navbar } from './Navbar/Navbar';

export const Layout = () => {
    const outlet = useOutlet();
    return (
        <div className={styles.layout}>
            <Navbar />
            {outlet}
            <Footer />
        </div>
    );
};
