import { useParams } from 'react-router-dom';
import styles from './Footer.module.scss';

export const Footer = () => {
    const locale = useParams().locale;

    const contactPageUrl = `/${locale}/contact`;
    const aboutUsPageUrl = `/${locale}/over-ons`;
    const privacyPageUrl = `/${locale}/policies`;
    return (
        <div className={styles.footer}>
            <div className={styles.content}>
                <div className={styles.icons}>
                    <a
                        href='https://www.facebook.com/Kieskompas/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/images/facebook-black-icon.svg' alt='facebook' />
                    </a>
                    <a
                        href='https://www.instagram.com/kieskompas/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/images/instagram-black-icon.svg' alt='instagram' />
                    </a>
                    <a
                        href='https://twitter.com/kieskompas'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/images/twitter-black-icon.svg' alt='twitter' />
                    </a>
                    <a
                        href='https://nl.linkedin.com/company/kieskompas'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src='/images/linkedin-black-icon.svg' alt='linkedin' />
                    </a>
                </div>
                <div className={styles.buttons}>
                    <a href={contactPageUrl}>
                        <button>Contact</button>
                    </a>
                    <a href={aboutUsPageUrl}>
                        <button>{locale === 'nl' ? 'Over ons' : 'About us'}</button>
                    </a>
                    <a href={privacyPageUrl}>
                        <button>Privacy</button>
                    </a>
                </div>
            </div>
        </div>
    );
};
